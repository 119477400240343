import { Link } from 'gatsby';
import React, { VFC } from 'react';

import { PageContent } from '@stur/components/layout/page-content';
import { PageTitle } from '@stur/components/layout/page-title';
import { RoutingUtils } from '@stur/utils/routing-utils';

export const NotFoundPage: VFC = () => {
  return (
    <>
      <PageTitle header="404: Not Found" />
      <PageContent>
        <div className="grid-container">
          <Link to={RoutingUtils.routes.dashboard()}>Go to the home page</Link>
        </div>
      </PageContent>
    </>
  );
};
